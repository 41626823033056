<template lang="pug">
  BTabs(
    fill
    nav-class="tabs-list"
  )
    BTab(
      v-for="ota in otaList"
      :title="ota.name"
      :title-item-class="['tab', `ota-id-${ota.id}`]"
      :key="ota.id"
      @click="handleTabClick"
    )
      AccountList(
        :accounts="accountsPerOTA[ota.id]"
        :max-ota-accounts-count="getMaxOtaAccountsCount(ota.id)"
        :ota="ota"
        :imports-progress="importsProgress"
        :loading="loading"
        @set-account="$emit('set-account', $event)"
        @add-new="$emit('add-new', $event)"
        @delete-account="$emit('delete-account', $event)"
        @save-account="$emit('save-account', $event)"
        @deactivate-account="$emit('deactivate-account', $event)"
      )
    BTab(
      v-if="isCompanySystemAvailable"
      :title="$t('header.title.company_system')"
      key="company-system"
      @click="handleTabClick"
    )
      CompanySystemAccountList()
    BTab(
      v-if="isNicolasTabShown"
      :title="$t('header.title.nicolas')"
      key="nicolas-ota"
      @click="handleTabClick"
    )
      NicolasAccountList(
        :accounts="accountsPerOTA[nicolasOta.id]"
        :imports-progress="importsProgress"
        @save-account="$emit('save-account', $event)"
      )
</template>

<script>
  // misc
  import { OTAS_WITH_COMPANY_SYSTEM, NICOLAS_OTA_RAW_NAME } from "@/config/constants"
  import { mapGetters } from "vuex"
  import { groupBy, keyBy, filter, isEmpty, find } from "lodash-es"

  export default {
    props: {
      accounts: Array,
      loading: Boolean,
      importsProgress: Object
    },

    components: {
      AccountList: () => import("@/pages/OtaAccounts/AccountList"),
      NicolasAccountList: () => import("./NicolasAccountList"),
      CompanySystemAccountList: () => import("./CompanySystemAccountList")
    },

    computed: {
      ...mapGetters(["translatedOtaList", "currentOrganization", "isCompanySystemAvailable", "isNicolasEnabled"]),

      isNicolasTabShown() {
        return this.isNicolasEnabled && !isEmpty(this.nicolasOta)
      },

      accountsPerOTA() {
        return groupBy(this.accounts, "ota.id")
      },

      currentOrganizationOtas() {
        return keyBy(this.currentOrganization.otas, "id")
      },

      nicolasOta() {
        return find(this.translatedOtaList, ({ rawName }) => rawName === NICOLAS_OTA_RAW_NAME)
      },

      otaList() {
        let otaList = this.translatedOtaList

        if (this.isCompanySystemAvailable) {
          otaList = filter(otaList, ({ rawName }) => !OTAS_WITH_COMPANY_SYSTEM.includes(rawName))
        }

        if (this.isNicolasEnabled) {
          otaList = filter(otaList, ({ rawName }) => rawName !== NICOLAS_OTA_RAW_NAME)
        }

        return otaList
      }
    },

    methods: {
      handleTabClick(e) {
        e.target.scrollIntoView({ behavior: "smooth", inline: "center" })
      },

      getMaxOtaAccountsCount(id) {
        return this.currentOrganizationOtas[id].max_ota_accounts_count
      }
    }
  }
</script>

<style lang="sass">
  .tabs-list
    overflow-x: auto
    overflow-y: hidden
    flex-wrap: nowrap

    .tab
      white-space: nowrap
</style>
